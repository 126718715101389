import React from 'react';
import { Link, Element } from 'react-scroll';
import './App.css';
// Import your component files
import Home from './pages/Home';
import Services from './pages/Services';
import About from './pages/About';
import Contact from './pages/Contact';
import Testimonials from './pages/Testimonials';



const testimonialData = [
  // ... populate this array with your testimonials
  {
    name: "Jane Doe",
    quote: "WizBuild’s services are top-notch. The quality of the materials and the professionalism of the team were outstanding.",
    position: "CEO",
    company: "BuildRight Inc.",
    image: "./assets/pfp3.jpg" // This should be a public URL or local path to an image
  },
  {
    name: "John Smith",
    quote: "I was impressed with the quick turnaround and the attention to detail. WizBuild exceeded our expectations.",
    position: "Project Manager",
    company: "ConstructoMax",
    image: "./assets/pfp3.jpg"
  },
];


const App = () => (
  <div>
    <header>
      <nav>
        <ul>
          <li><Link to="home" smooth={true} duration={500}>Home</Link></li>
          <li><Link to="services" smooth={true} duration={500}>Services</Link></li>
          <li><Link to="about" smooth={true} duration={500}>About</Link></li>
          <li><Link to="contact" smooth={true} duration={500}>Contact</Link></li>
        </ul>
      </nav>
    </header>
    <main>
      <Element name="home"><Home /></Element>
      <Element name="services"><Services /></Element>
      <Element name="about"><About /></Element>
      <Element name="testimonials"><Testimonials testimonials={testimonialData} /></Element>
      <Element name="contact"><Contact /></Element>
    </main>
    <footer>
      {/* Footer content */}
    </footer>
  </div>
);

export default App;
