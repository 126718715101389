import React from 'react';
import './Home.css'; // Advanced styling

const Home = () => (
    <div className="home">
        <section className="hero">
            <h1>Your Project, Our Priority</h1>
            <p>Empowering construction projects with top-grade materials and services.</p>
            <button className="cta">Discover More</button>
        </section>
        <section className="why-choose-us">
            <div className="container">
                {/* Example USP */}
                <div className="usp">
                    <h3>Unmatched Quality</h3>
                    <p>Every brick, every grain of sand meets the highest standards.</p>
                </div>
                {/* Additional USPs */}
            </div>
        </section>

    </div>
);

export default Home;
