import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Testimonials.css'; // Your own CSS file to customize the slick slider

const Testimonials = ({ testimonials }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    cssEase: 'linear'
  };

  return (
    <div className="testimonials">
      <h2>Client Testimonials</h2>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-item">
            <img src={testimonial.image} alt={testimonial.name} />
            <p>{testimonial.quote}</p>
            <h4>{testimonial.name}</h4>
            <h5>{testimonial.company}</h5>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Testimonials;
