import React from 'react';
import './Contact.css'; // Easy-to-use contact form and additional contact details

const Contact = () => (
    <div className="contact">
        <h2>Get in Touch</h2>
        <form>
            <input type="text" placeholder="Your Name" required />
            <input type="email" placeholder="Your Email" required />
            <textarea placeholder="Your Message" required></textarea>
            <button type="submit">Send Message</button>
        </form>
        {/* Optionally, include address, map, and operational hours */}
    </div>
);

export default Contact;
