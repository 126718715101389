import React from 'react';
import './Services.css'; // Detailed and engaging service descriptions

const Services = () => (
    <div className="services">
        <h2>Our Expertise</h2>
        <div className="service">
            <h3>Brick Making</h3>
            <p>Custom bricks tailored to your project's unique needs, combining traditional craftsmanship with modern technology for unparalleled quality.</p>
        </div>
        <div className="service">
            <h3>Aggregate Creation</h3>
            <p>Superior aggregate materials from our state-of-the-art crushing facility, designed for both sustainability and performance in all construction contexts.</p>
        </div>
        <div className="service">
        <h3>Premium Bulk Sand</h3>
        <p>Our high-quality sand is sourced from the finest quarries, meticulously processed to ensure uniform grain size, and perfect for a wide range of construction needs. From major infrastructure projects to beautiful landscaping, our bulk sand provides the foundation for excellence.</p>        </div>
        <div className="service">
            <h3>Concrete / Stone Recycling Services</h3>
            <p>Innovative recycling of concrete waste into reusable materials, supporting your project's efficiency and environmental footprint.</p>
        </div>
        {/* Additional services as needed */}
    </div>
);

export default Services;
