import React from 'react';
import './About.css'; // Introduce your company, team, and values

const About = () => (
    <div className="about">
        <h2>About WizBuild</h2>
        <p>We're not just a supplier; we're your partner in building a better future. Learn more about our journey, our team, and the values that drive us.</p>
        {/* Include timeline, team introductions, etc. */}
    </div>
);

export default About;
